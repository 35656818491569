import "../styles/globals.css";
import { useEffect } from "react";
import { Router } from "next/router";
import { ApolloProvider } from "@apollo/react-hooks";
import { UserProvider } from "@auth0/nextjs-auth0";
import { useApollo } from "../lib/apolloClient";
import NProgress from "nprogress";
import "nprogress/nprogress.css"; //styles of nprogress
import { AbilityProvider } from "../utils/casl/abilityContext";
import { AccessProvider } from "../utils/accessContext";

function MyApp({ Component, ctx, pageProps }) {
  const apolloClient = useApollo(pageProps, ctx);
  useEffect(() => {
    Router.events.on("routeChangeStart", () => NProgress.start());
    Router.events.on("routeChangeComplete", () => NProgress.done());
    Router.events.on("routeChangeError", () => NProgress.done());
  }, []);
  return (
    <ApolloProvider client={apolloClient}>
      <UserProvider>
        <AccessProvider>
          <AbilityProvider>
            <Component {...pageProps} />
          </AbilityProvider>
        </AccessProvider>
      </UserProvider>
    </ApolloProvider>
  );
}
export default MyApp;

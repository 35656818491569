import { useMemo } from "react";
import merge from "deepmerge";
import isEqual from "lodash/isEqual";
import { WebSocketLink } from "apollo-link-ws";
import { onError } from "apollo-link-error";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { GetServerSidePropsContext } from "next";
import { getSession } from "@auth0/nextjs-auth0";
import Router from "next/router";
import { redirectHome } from "../utils/redirectHome";
import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/react-hooks";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient;
let accessToken = null;
let ctx: GetServerSidePropsContext;

const requestAccessToken = () => {
  if (accessToken || !ctx) return;
  const session = getSession(ctx?.req, ctx?.res);
  accessToken = session?.accessToken;
};

const getServerSideHeaders = () => {
  requestAccessToken();
  if (accessToken == null) return {};
  return {
    authorization: `Bearer ${accessToken}`,
  };
};

const getClientHeaders = async () => {
  let res = await fetch(`${process.env.AUTH0_BASE_URL}/api/auth/session`);
  let data = await res.json();
  accessToken = data.accessToken;

  return accessToken ? { authorization: `Bearer ${accessToken}` } : {};
};

const createHttpLink = () => {
  const httpLink = new HttpLink({
    uri: `${process.env.HASURA_GRAPHQL_ENDPOINT}/v1/graphql`,
    credentials: "include",
    headers: getServerSideHeaders(), // auth token is fetched on the server side
    fetch,
  });
  return httpLink;
};

const createWSLink = () => {
  return new WebSocketLink(
    new SubscriptionClient(`${process.env.HASURA_WEBSOCKET_LINK}/v1/graphql`, {
      lazy: true,
      reconnect: true,
      connectionParams: async () => {
        return {
          headers: await getClientHeaders(),
        };
      },
    })
  );
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const netError: any = networkError;
  if (graphQLErrors) {
    console.log("GRAPHQL ERROR");
    graphQLErrors.map(({ extensions }) => {
      if (extensions && extensions.code === `invalid-jwt`) {
        accessToken = null;
        const ssrMode = typeof window === "undefined";
        if (ssrMode) {
          return redirectHome;
        } else {
          Router.push("/");
        }
      }
    });
  } else if (networkError) {
    switch (netError?.extensions?.code) {
      case "start-failed":
        Router.push("/");
        break;
      case "invalid-jwt":
        Router.push("/");
        break;
      default:
        console.log(netError);
        break;
    }
  }
});

function createApolloClient(initialState) {
  const ssrMode = typeof window === "undefined";
  let link;
  if (ssrMode) {
    link = createHttpLink(); // executed on server
  } else {
    link = createWSLink(); // executed on client
  }
  return new ApolloClient({
    ssrMode,
    link: from([errorLink, link]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            //  allPosts: concatPagination(),
          },
        },
      },
    }),
  });
}

export function initializeApollo(
  initialState = null,
  context: GetServerSidePropsContext = null
) {
  if (context) ctx = context;
  const _apolloClient = apolloClient ?? createApolloClient(initialState);
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps, ctx) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state, ctx), [state]);
  return store;
}

import { createContext, useState } from "react";
import { createContextualCan } from "@casl/react";
import { useUser } from "@auth0/nextjs-auth0";
import { Ability, AbilityBuilder, defineAbility } from "@casl/ability";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";

const AbilityContext = createContext(null);
const Can = createContextualCan(AbilityContext.Consumer);
const CaslAbility = defineAbility((can, cannot) => {});

const AbilityProvider = ({ children }) => {
  const { user } = useUser();
  const [ability, setAbility] = useState(CaslAbility);
  const [roles, setRoles] = useState(null);

  useEffect(() => {
    fetchUserRoles();
  }, [user]);

  const fetchUserRoles = async () => {
    let res = await fetch(`${process.env.AUTH0_BASE_URL}/api/auth/session`);
    let data = await res.json();
    let accessToken = data.accessToken;
    if (accessToken) {
      let userObject = jwt_decode(accessToken);
      let userRoles =
        userObject?.["https://hasura.io/jwt/claims"]?.[
          "x-hasura-allowed-roles"
        ];
      setRoles(userRoles);
    }
  };

  function defineAbilityForUser() {
    const { can, rules } = new AbilityBuilder(Ability);

    if (roles.includes("org-admin")) {
      can("view", "admin");
      can("add", "member");
      can("add", "employment");
      can("view", "profileOptions");
      can("view", "profile");
    }
    return new Ability(rules);
  }

  useEffect(() => {
    if (user && roles) {
      setAbility(defineAbilityForUser());
    }
  }, [roles]);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};

export { Can, AbilityContext, AbilityProvider };

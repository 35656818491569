import { createContext, useState } from "react";
import { useUser } from "@auth0/nextjs-auth0";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useRouter } from "next/router";

const AccessContext = createContext(null);
const excludedRoutes = [
  '/',
  '/registration',
  '/terms',
  '/account/no-access'
]

const excludedRegexRoutes = [
  new RegExp('^/company/')
]

const AccessProvider = ({ children }) => {
  const { user } = useUser();
  const [claims, setClaims] = useState(null);

  const router = useRouter();

  useEffect(() => {
    checkAccessForUser();
  }, [router.pathname, claims]);

  useEffect(() => {
    fetchUserClaims();
  }, [user]);

  const fetchUserClaims = async () => {
    let res = await fetch(`${process.env.AUTH0_BASE_URL}/api/auth/session`);
    let data = await res.json();
    let accessToken = data.accessToken;
    if (accessToken) {
      let userObject = jwt_decode(accessToken);
      let userRoles =
        userObject?.["https://hasura.io/jwt/claims"]?.[
          "x-hasura-allowed-roles"
        ];

      const tosAcceptedAt =
        userObject?.["https://app.palma.hn/jwt/claims"]?.["tosAcceptedAt"];
      const accountStatus =
        userObject?.["https://app.palma.hn/jwt/claims"]?.["accountStatus"];
      
      const claims = {
        userRoles,
        accountStatus,
        tosAcceptedAt
      }

      setClaims(claims);
    }
  };

  function checkAccessForUser() {
    if (claims) {
      if (!excludedRoutes.includes(router.pathname) && !excludedRegexRoutes.some(exp => exp.test(router.pathname))) {
        if (claims.accountStatus != "active") {
          claims.userRoles.includes("org-admin")
            ? router.push("/account/reactivate")
            : router.push("/account/no-access");
        }
      }
    }
  }

  return (
    <AccessContext.Provider value={claims}>
      {children}
    </AccessContext.Provider>
  );
};

export { AccessProvider };
